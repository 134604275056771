/* ./src/index.css */
/* deploy test purpose only changes */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Roboto:400');

:root {
    --white: #FFFFFF;
    --primary: #2F394D;
    --success: #439775;
    --error: #FE6D73;
    --gray-light: #CCCCCC;
    --gray: #777777;
    --background: #F6F6F6;
    --black: #333333;
    --base-font-size: 16px;
    --line-height: 1.75;
    --leading: calc(var(--base-font-size) * var(--line-height));
    --font-size-sm: 0.833rem;
    --font-size-md: 1.2rem;
    --font-size-lg: 1.44rem;
    --font-size-xl: 1.728rem;
    --font-size-2xl: 2.074rem;
    --font-size-3xl: 2.488rem;
    --max-width: 80rem; /* 1280px */
    --spacing-xs: 0.25rem;
    --spacing-sm: 0.5rem;
    --spacing-md: 1rem;
    --spacing-lg: 2rem;
}

html {
    font-size: 100%;
} /*16px*/

body {
    background: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: var(--line-height);
    color: #333333;
}

.max-width {
    width: 100%;
    max-width: var(--max-width);
}

.heading-3xl {
    font-size: var(--font-size-3xl);
    font-weight: bold;
    margin-bottom: var(--spacing-md);
}

.heading-2xl {
    font-size: var(--font-size-2xl);
    font-weight: bold;
    margin-bottom: var(--spacing-md);
}

.heading-xl {
    font-size: var(--font-size-xl);
    font-weight: bold;
    margin-bottom: var(--spacing-sm);
}

.heading-lg {
    font-size: var(--font-size-lg);
    font-weight: bold;
    margin-bottom: var(--spacing-sm);
}

.heading-md {
    font-size: var(--font-size-md);
    margin-bottom: var(--spacing-sm);
}

.paragraph {
    max-width: 65ch;
    margin-bottom: var(--spacing-lg);
}

.btn {
    @apply border;
    @apply rounded;
    @apply py-xs;
    @apply px-md;
    @apply transition;
    height: 3rem;
}

.btn-primary {
    @apply bg-primary;
    @apply text-white;
}

.btn-danger {
    @apply bg-error;
    @apply text-white;
    @apply border-error;
}

.btn-danger-outline {
    @apply text-error;
    @apply border-error;
}

.btn:disabled {
    @apply bg-background;
    @apply cursor-not-allowed;
}

.btn-sm {
    font-size: 0.8rem;
    height: 2.5rem;
}

.card {
    @apply rounded;
    @apply bg-white;
}

.table {
    @apply w-full;
    @apply border-collapse;
}

.table tr {
    @apply border-b;
    @apply border-gray-light;
}

.table th {
    @apply text-left;
    @apply font-bold;
    @apply py-xs;
}

.table td {
    @apply py-sm;
}

.tab {
    width: 100%;
    padding: var(--spacing-sm) var(--spacing-md);
    text-align: left;
    font-size: inherit;
    border-top: none;
    border-bottom: none;
    border-right: none;
    background: none;
    font-weight: bold;
    color: #777777;
    text-transform: uppercase;
    border-left: 2px solid transparent;
}

.tab.active {
    color: var(--primary-color);
    border-left-color: var(--primary-color);
}

.tab:hover {
    cursor: pointer;
}

.tab:focus {
    outline: none;
}
